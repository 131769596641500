<template>
  <div
    class="interface-facture"
    :class="{ 'interface-facture-decalage': computedRouterLinkFacture }"
  >
    <div class="cadre-block-filter">
      <div class="right-block">
        <!-- DATE DEBUT -->
        <div class="date-input-vuetify mr-1" v-if="checkPermission('FSDDF')">
          <v-menu
            v-model="isDatePickerDebutVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_debut"
                label="Date début"
                outlined
                readonly
                hide-details
                clearable
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleResetDate('date_debut')"
                color="#704ad1"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date_debut"
              @input="handleDateTimePicker(date_debut, 'date_debut')"
              no-title
              locale="fr"
              :allowed-dates="disabledStartDate"
              color="#704ad1"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <!-- DATE FIN -->
        <div class="date-input-vuetify mr-1" v-if="checkPermission('FSDDF')">
          <v-menu
            v-model="isDatePickerFinVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_fin"
                label="Date fin"
                outlined
                readonly
                hide-details
                v-on="on"
                v-bind="attrs"
                clearable
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleResetDate('date_fin')"
                color="#704ad1"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_fin"
              @input="handleDateTimePicker(date_fin, 'date_fin')"
              no-title
              locale="fr"
              :allowed-dates="disabledEndDate"
              color="#704ad1"
            ></v-date-picker>
          </v-menu>
        </div>
        <!-- SELLECT MONTH -->
        <div class="select-input-vuetify" v-if="checkPermission('FSFAM')">
          <v-select
            v-model="filterYearMonth"
            :items="[{ value: null, text: 'Tous' }, ...computedSousObjets]"
            dense
            outlined
            hide-details
            class="ma-2"
            label="Mois"
            @change="handleFilter"
            no-data-text="Aucun élément trouvé"
            :menu-props="{ bottom: true, offsetY: true }"
            color="#704ad1"
            item-color="#704ad1"
          ></v-select>
        </div>
        <!-- UPLOAD FILE -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <input-file-excel-num-dossier
                v-show="true"
                :value="nums"
                @searchWithNumDossier="searchWithMultipleFacture"
                :fetchFuntion="fecthFactureNumbersInExcelFile"
                :showCount="true"
                hiddenContent="true"
              />
            </div>
          </template>
          <span>Choisir un fichier</span>
        </v-tooltip>
        <!-- COONTENT LOADING AND MESSAGE ERROR -->
        <!-- <div v-if="loadingSci" class="chargement chargement-loading-icon">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->
        <v-progress-circular
          class="ml-2"
          v-if="loadingSci"
          indeterminate
          color="#704ad1"
        ></v-progress-circular>
        <div class="notif-export-file-sci ml-2" v-if="getShowNotifExportFile">
          Préparation du fichier en cours ...
        </div>
        <div class="error-message ml-2 mr-2">
          <div v-if="errorSci">
            <ul v-if="Array.isArray(errorSci)" class="mb-0">
              <li v-for="(e, index) in errorSci" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ errorSci }}</div>
          </div>
        </div>
      </div>
      <div class="left-block">
        <!-- SEARCH -->
        <div class="serach-input mr-2">
          <search
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            v-if="checkPermission('FSR')"
            hiddenContent="true"
          />
        </div>
        <!-- ADD FACTURE -->
        <add-facture-sci-mensuelle
          :type_produit="type_produit"
          @reloadData="reloadData"
          v-if="checkPermission('FSAF')"
          hiddenContent="true"
        />
        <!-- EXPORT EXCEL -->
        <export-dynamic
          v-if="checkPermission('FSTX') || checkPermission('FSTZ')"
          :filter="computedFilter"
          :selected="computedCheckRowsExport"
          :exportXls="exportFactureLibre"
          :exportZip="downloadZIP"
          :famille="support"
          :zipRequired="checkPermission('FSTZ')"
          :xlsRequired="checkPermission('FSTX')"
          hiddenContent="true"
        />
        <!-- VALIDATION FACTURE  -->
        <validate-multi-facture
          v-if="computedCheckedRowsMultiple.length && checkPermission('FSVMF')"
          @reloadData="reloadData"
          :dataToUse="computedCheckedRowsMultiple"
          hiddenContent="true"
          type="sci"
        />
        <!-- TRANSFERT FACTURE -->
        <transfert-facture-to-libre
          v-if="
            computedCheckRowsToTransfertLibre && checkPermission('FSTFATFL')
          "
          :interfaceName="this.$options.name"
          :dataToUse="computedCheckRowsToTransfertLibre"
          :indexsToRemove="computedGetIndex"
          hiddenContent="true"
          type="sci"
        />
        <!-- REGLEMENT FACTURE  -->
        <reglement
          class="mr-1"
          v-if="checkPermission('FSACSAR') && computedCheckedRowsOneClient"
          :dataToUse="computedCheckedRowsOneClientFacture"
          interfaceModal="ReglementFactureRowLibre"
          hiddenContent="true"
        />
        <!-- GENERATION AVOIR -->
        <generate-avoir
          v-if="computedCheckedRowsAvoir.length && checkPermission('FSGFA')"
          :interfaceName="this.$options.name"
          :selected="computedCheckedRowsAvoir"
          @RefreshData="handleFilter"
          :functionGenerate="avoirGlobaleFactureSci"
          :famille="support"
          hiddenContent="true"
          :permissionDate="checkPermission('FSACSDCA')"
        />
        <!-- SEND EMAIL -->
        <sendMail
          v-if="computedDataToSend.length > 0 && checkPermission('FSTHEM')"
          :dataToUse="computedDataToSend"
          @eventMailSendRefreshTable="handleFilter()"
          hiddenContent="true"
        />
        <!-- FILTER -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-if="
                checkPermission('FSFS') ||
                  checkPermission('FSFC') ||
                  checkPermission('FSFST') ||
                  checkPermission('FSFSC')
              "
              size="sm"
              v-on="on"
              v-bind="attrs"
              @click="openPopover"
              class="block-icon mr-2 button-height-unset"
            >
              <IconFiltre></IconFiltre>
            </div>
          </template>
          <span>Choisir un Filtre</span>
        </v-tooltip>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="350"
          origin="top right"
          content-class="popover-menu-sci"
        >
          <v-card>
            <v-divider class="line-hr-filter"></v-divider>
            <v-card-text class="body-card filter-content-popup">
              <v-form>
                <!-- SOCIETE -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('FSFS')"
                  v-model="isLocalStorageVendeur"
                  :items="computedVendeurOnline"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Société"
                  multiple
                  :loading="getLoadingSciVendeurOnLine"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageVendeur.length - 0 }} Société)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageVendeur.length - 0 }} Société)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- CLIENT -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('FSFC')"
                  v-model="isLocalStorageAcheteur"
                  :items="computedAcheteurOnline"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Client"
                  multiple
                  :loading="getLoadingSciAcheteurOnLine"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageAcheteur.length - 0 }} Clients)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageAcheteur.length - 0 }} Clients)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- STATUTS -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('FSFST')"
                  v-model="isLocalStorageStatut"
                  :items="computedValidationListFilter"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Statut"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageStatut.length - 0 }} Statuts)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageStatut.length - 0 }} Statuts)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- STATUT CLIENT -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('FSFSC')"
                  v-model="isLocalStorageStatutClient"
                  :items="computedValidationListFilter"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Statut client"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageStatutClient.length - 0 }} Statuts
                      clients)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageStatutClient.length - 0 }} Statuts
                      clients)
                    </span>
                  </template>
                </v-autocomplete>
              </v-form>
            </v-card-text>
            <v-card-actions class="popover-action-filter">
              <v-btn
                class="ma-2 capitalize-text"
                outlined
                color="#5E35B1"
                @click="validerPopover"
              >
                Appliquer
              </v-btn>
              <v-btn
                class="ma-2 ml-2 capitalize-text"
                text
                @click="closePopover"
              >
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div class="content-cadre-table">
      <!-- BLOCK SELON FILTER SELECTIONNEE -->
      <div>
        <div class="block-filter-style ml-0 mb-2" v-if="returnTableChips">
          <span class="phrase-style">Les filtres choisie sont: </span>
          <span class="item-filter-style" v-if="date_debut !== null"
            >Date début : {{ date_debut }}</span
          >
          <span class="item-filter-style" v-if="date_fin !== null"
            >Date fin : {{ date_fin }}</span
          >
          <span v-if="searchValue" class="item-filter-style"
            >Recherche : {{ searchValue }}</span
          >
          <span v-if="filterYearMonth != null" class="item-filter-style"
            >Mois : {{ filterYearMonth }}</span
          >
        </div>
      </div>
      <!-- BLOCK SELON FILTER POPOVER SELECTIONNEE -->
      <v-row class="filter-Block-icon" v-if="returnTableFiltre">
        <!-- SOCIETE -->
        <v-col
          :cols="computedLengthFilter"
          v-if="vendeur.length"
          class="space-table-filter"
        >
          <span class="title-block-chip-filter ml-3">Société : </span>
          <v-sheet elevation="1" class="py-0 px-1 ml-3">
            <v-chip-group mandatory>
              <v-chip
                :key="v.text + 'proprietaire'"
                v-for="v in vendeur"
                close
                @click:close="
                  remove(v, 'vendeur', 'handleFilter', 'isLocalStorageVendeur')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- CLIENT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="acheteur.length"
          class="space-table-filter"
        >
          <span class="title-block-chip-filter">Client : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="a.text + 'proprietaire'"
                v-for="a in acheteur"
                close
                x-small
                @click:close="
                  remove(
                    v,
                    'acheteur',
                    'handleFilter',
                    'isLocalStorageAcheteur'
                  )
                "
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ a.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- STATUT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="statut.length"
          class="space-table-filter"
        >
          <span class="title-block-chip-filter">Statut : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="s.text + 'proprietaire'"
                v-for="s in statut"
                close
                @click:close="
                  remove(s, 'statut', 'handleFilter', 'isLocalStorageStatut')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- STATUT CLIENT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="statut_client.length"
          class="space-table-filter"
        >
          <span class="title-block-chip-filter">Statut client : </span>
          <v-sheet elevation="1" class="py-0 px-1 mr-3">
            <v-chip-group mandatory>
              <v-chip
                :key="s.text + 'proprietaire'"
                v-for="s in statut_client"
                close
                @click:close="
                  remove(
                    v,
                    'statut_client',
                    'handleFilter',
                    'isLocalStorageStatutClient'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
      </v-row>
      <!-- TABLE FACTURE SCI -->
      <!-- :loading="loadingSci" -->
      <v-data-table
        v-model="selectedTable"
        :headers="computedFields"
        :items="factureScis"
        @item-expand="factureScis"
        :class="{
          'height-filter-chips-table': returnTableChips && !returnTableFiltre,
          'height-filter-table': returnTableFiltre && !returnTableChips,
          'height-filter-tous': returnTableChips && returnTableFiltre
        }"
        class="table-facture-sci"
        hide-default-footer
        show-expand
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :calculate-widths="false"
        :fixed-header="true"
        item-key="name"
        hide-action
        :no-data-text="
          loadingSci
            ? 'Chargement... Veuillez patienter'
            : `Il n'y a aucune donnée à afficher dans ce tableau.`
        "
      >
        <!-- HEADERS TABLE -->
        <template v-slot:[`header.check_all`]="{ header }">
          <td>
            <span v-if="header.value != 'check_all'"> {{ header.label }}</span>
            <div v-else>
              <v-checkbox
                class="checked-global"
                v-model="checkAll"
                @change="checkAllFunction"
                color="#704ad1"
                :true-value="true"
                :false-value="false"
                hide-details
              ></v-checkbox>
            </div>
          </td>
        </template>
        <!-- BODY TABLE -->
        <template v-slot:[`item.check_all`]="{ item }">
          <td class="custom-cell sticky-header-1">
            <v-checkbox
              class="checked-groupe"
              v-model="item.check"
              @change="checkAllVerif(item)"
              color="#704ad1"
              :true-value="true"
              :false-value="false"
              hide-details
            ></v-checkbox>
          </td>
        </template>
        <!--  ICON EXPANDED -->
        <template v-slot:[`item.data-table-expand`]="{ item }">
          <td class="custom-cell sticky-header-2">
            <button
              class="btn btn-anomalie"
              v-if="item && item.factures && item.factures.length > 0"
              @click="showRowDetailsVueDefault(item)"
            >
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                color="#704ad1"
                title="Afficher Facture(s)"
                v-if="item.showDetails == false"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                color="#704ad1"
                title="Masquer Facture(s)"
                v-if="item.showDetails == true"
              />
            </button>
          </td>
        </template>
        <template v-slot:[`item.Vendeur`]="{ item }">
          <td class="custom-cell">{{ item.name ? item.name : '-' }}</td>
        </template>
        <template v-slot:[`item.Acheteur`]="{ item }">
          <td class="custom-cell">{{ item.vendeur ? item.vendeur : '-' }}</td>
        </template>
        <template v-slot:[`item.sous_objet`]="{ item }">
          <td class="custom-cell">{{ item.vendeur ? item.vendeur : '-' }}</td>
        </template>
        <template v-slot:[`item.num`]="{ item }">
          <td class="custom-cell">{{ item.vendeur ? item.vendeur : '-' }}</td>
        </template>
        <template v-slot:[`item.numero_avoir`]="{ item }">
          <td class="custom-cell">{{ item.vendeur ? item.vendeur : '-' }}</td>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <td class="custom-cell">{{ item.vendeur ? item.vendeur : '-' }}</td>
        </template>
        <template v-slot:[`item.total_net_ht`]="{ item }">
          <td class="custom-cell">{{ item.total_net_ht }} €</td>
        </template>
        <template v-slot:[`item.total_tva`]="{ item }">
          <td class="custom-cell">{{ item.total_tva }} €</td></template
        >
        <template v-slot:[`item.total_ttc`]="{ item }">
          <td class="custom-cell">{{ item.total_ttc }} €</td></template
        >

        <template v-slot:[`item.etat`]="{ item }">
          <td class="custom-cell">{{ item.vendeur ? item.vendeur : '-' }}</td>
        </template>
        <template v-slot:[`item.statut_client`]="{ item }">
          <td class="custom-cell">{{ item.vendeur ? item.vendeur : '-' }}</td>
        </template>
        <template v-slot:[`item.reglement`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.historique`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.reste`]="{}">
          <td class="custom-cell">-</td></template
        >
        <template v-slot:[`item.date_paiement`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <!-- EXPANDED TABLE -->
        <template #expanded-item="{ headers,item }">
          <td
            class="td-expanded"
            :colspan="headers.length"
            v-if="item.showDetails"
          >
            <table :items="item.factures" class="table-expanded">
              <tbody>
                <tr
                  v-for="(data, index) in item.factures"
                  :key="data.id + 'sci' + index"
                >
                  <!-- CHECK -->
                  <td
                    class="td-expanded sticky-header-1"
                    style="width : 40px !important ; min-width : 40px !important"
                  >
                    <v-checkbox
                      class="checked-expanded"
                      v-model="data.check"
                      color="#704ad1"
                      :true-value="true"
                      :false-value="false"
                      hide-details
                    ></v-checkbox>
                  </td>
                  <!-- VIDE -->
                  <td
                    class="td-expanded sticky-header-2"
                    style="width : 40px !important ; min-width : 40px !important"
                  >
                    <span> </span>
                  </td>
                  <!-- SOCIETE -->
                  <td class="td-expanded" v-if="checkPermission('FSACS')">
                    {{
                      data.vendeur && data.vendeur.nom_compagnie
                        ? data.vendeur.nom_compagnie
                        : data.vendeur
                    }}
                  </td>
                  <!-- CLIENT -->
                  <td class="td-expanded" v-if="checkPermission('FSACC')">
                    {{ data.acheteur }}
                  </td>
                  <!-- MOIS -->
                  <td class="td-expanded" v-if="checkPermission('FSACMF')">
                    {{ data.sous_objet != null ? data.sous_objet : '-' }}
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FSACNF')">
                    <div class="flex-div">
                      {{ data.num }}
                      <VisualisationFacture
                        :awesome="true"
                        :facture="data.id"
                        :permission="checkPermission('FSAFM')"
                      ></VisualisationFacture>
                    </div>
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FSACNFA')">
                    <div class="flex-div">
                      <AvoirFacture
                        v-if="
                          data.type_facture != 'Facture proforma' &&
                            data.etat != 'Annulée' &&
                            data.type !== 'separation'
                        "
                        :item="data"
                        :functionCreate="createAvoirSci"
                        :permission="checkPermission('FSGAP')"
                        :index="
                          data.vendeur && data.vendeur.nom_compagnie
                            ? data.vendeur.nom_compagnie
                            : data.vendeur + index
                        "
                        :gettersData="produitsFactureFrais"
                        :methodProduct="getProductsFraisFacture"
                        :permissionDate="checkPermission('FSACSDCA')"
                      ></AvoirFacture>
                      <PopoverAvoirFacture
                        v-if="
                          data.FactureAvoir && data.FactureAvoir.length != 0
                        "
                        :factureAvoir="data.FactureAvoir"
                        :permissionTelecharger="checkPermission('FSTFAP')"
                        :permissionVoirFacture="checkPermission('FSAFM')"
                        type="sci"
                      >
                      </PopoverAvoirFacture>
                    </div>
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FSACD')">
                    {{ data.date_creation | formateDate }}
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FSACMH')">
                    {{ data.total_net_ht }} €
                    <!-- <EditableInput
                      :editable="
                        data.type == 'Facture proforma' &&
                          checkPermission('FSMMHT') &&
                          data.modifiable == true
                      "
                      champName="total_net_ht"
                      :item="data"
                      :value="data.total_net_ht"
                      type="text"
                      editableType="input"
                      :updateFunction="updateMontantHtFactureSci"
                      :defaultColor="true"
                      :uniteMontant="euro"
                      widthInput="110px"
                    /> -->
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FSACT')">
                    {{ data.total_tva }} €
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FSTTC')">
                    <TemplateFacture
                      :permissionDownloadFacture="checkPermission('FSTFP')"
                      :permissionDownloadFactureAvoir="
                        checkPermission('FSTFAP')
                      "
                      :permissionValidationFacture="checkPermission('FSVF')"
                      :famille="'sci'"
                      :getTemplateMethod="getTemplateFactureSci"
                      :getterTemplate="templateFactureSCI"
                      :validateMethod="validateFactureSciTemplate"
                      :item="data"
                      :project="data"
                      :componentName="$options.name"
                      :editable="
                        data.etat == 'Payé' ||
                        data.etat == 'Annulée' ||
                        data.modifiable == false
                          ? false
                          : checkPermission('FSVF')
                          ? true
                          : false
                      "
                      :download="data.etat == 'Annulée' ? false : data.id"
                    />
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FSACRAP')">
                    {{ data.reste }} €
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FSACSFA')">
                    <EditableInput
                      :editable="false"
                      champName="etat"
                      editableType="select"
                      :optionsSelect="computedListStatut"
                      :item="data"
                      :value="data.etat"
                      :updateFunction="updateStatutFactureSci"
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                      widthInput="110px"
                    />
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FSACSC')">
                    <div v-if="false">
                      <EditableInput
                        champName="statut_client"
                        editableType="select"
                        :optionsSelect="computedListStatutClient"
                        :item="data"
                        :value="data.statut_client"
                        :updateFunction="updateStatutClientFactureSci"
                        label="value"
                        :positionSelect="true"
                        formate="regieFacture"
                        :showIconEdit="true"
                        widthInput="110px"
                      />
                    </div>
                    <div v-else class="text-gras">
                      {{ data.statut_client ? data.statut_client : '-' }}
                    </div>
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FSACSCDP')">
                    {{ data.date_paiement }}
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FSACSCRG')">
                    <div class="flex-div">
                      {{ data.montant_regle }} €
                      <PopoverReglementFacture
                        v-if="data.reglements.length"
                        :factureReglement="data.reglements"
                        :permissionModifier="checkPermission('FSACSUR')"
                        dataInterface="sci"
                      >
                      </PopoverReglementFacture>
                    </div>
                  </td>
                  <td class="td-expanded" v-if="checkPermission('FSACHF')">
                    <Historique
                      :awesome="true"
                      :index="data.id"
                      :permission="checkPermission('FSACHF')"
                      :dataToUse="data.historique"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="footer-style-table">
      <div
        v-if="
          computedTotal &&
            (computedTotal.net_ht > 0 ||
              computedTotal.ttc > 0 ||
              computedTotal.someApaye > 0) &&
            checkPermission('GFMTHABT')
        "
        class="style-somme-th-ttc pl-2"
      >
        <div>
          <span class="bold-text"> Total HT:</span>
          {{ '   ' }}
          {{   computedTotal.net_ht
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '), }}
          € {{ '   ' }}
          <span class="bold-text">Total TTC:</span>
          {{ '   ' }}
          {{
            computedTotal.ttc
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replaceAll(',', ' ')
          }}
          € {{ '   ' }}
          <span class="bold-text"> Total à payer : </span>
          {{ '   ' }}
          {{
            computedTotal.someApaye
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replaceAll(',', ' ')
          }}
          €
        </div>
      </div>
      <div class="text-center pagination-table">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify">
        <v-select
          v-model="per_page"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetX: true
          }"
          color="#704ad1"
          item-color="#704ad1"
        ></v-select>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import EditableInput from '@/views/component/EditableInput.vue';
import ExportDynamic from '../ThProject/frais/fraisDynamic/component/exportDynamic.vue';
import moment from 'moment';
import ClickOutside from 'vue-click-outside';
export default {
  name: 'Gestion-Invest',
  data() {
    return {
      expanded: [],
      singleExpand: false,
      selectedTable: [],
      menu: false,
      factureToShow: null,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      page: 1,
      per_page: 20,
      vendeur: [],
      acheteur: [],
      statut_client: [],
      date_debut: null,
      date_fin: null,
      statut: [
        { value: 'A payer', text: 'A payer' },
        {
          value: 'Créé',
          text: 'Créé'
        },
        { value: 'Payé en partie', text: 'Payé en partie' }
      ],
      type_produit: 'bien',
      support: 'sci',
      column: 'id',
      sortBy: 'nom',
      searchValue: null,
      checkAll: false,
      indexSelectIsOpen: null,
      etatList: [
        { value: 'Créé', text: 'Créé' },
        { value: 'Payé', text: 'Payé' },
        { value: 'Payé en partie', text: 'Payé en partie' }
        // { value: 'Annulée', text: 'Annulée' }
      ],
      selected: [],
      error: null,
      isOpen: false,
      filterYearMonth: null,
      euro: ' €',
      nums: null,
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      isLocalStorageVendeur: [],
      isLocalStorageStatut: [],
      isLocalStorageAcheteur: [],
      isLocalStorageStatutClient: []
    };
  },
  filters: {
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(new Date(value)).format('DD-MM-YYYY');
      }
    }
  },
  computed: {
    ...mapGetters([
      'sousObjetsMensuels',
      'getShowNotifExportFile',
      'factureScis',
      'TotalRowsScis',
      'getDataSciVendeurOnLine',
      'getLoadingSciVendeurOnLine',
      'getDataSciAcheteurOnLine',
      'getLoadingSciAcheteurOnLine',
      'loadingSci',
      'errorSci',
      'checkPermission',
      'getUserData',
      'produitsFactureFrais',
      'templateFactureSCI',
      'computedRouterLinkFacture',
      'countNonEmptyArrays'
    ]),
    computedLengthFilter() {
      return this.countNonEmptyArrays(
        this.acheteur,
        this.statut,
        this.statut_client,
        this.vendeur
      );
    },
    returnTableChips() {
      if (
        this.date_debut !== null ||
        this.date_fin !== null ||
        this.searchValue !== null ||
        this.filterYearMonth != null
      ) {
        return true;
      }
      return false;
    },
    returnTableFiltre() {
      if (
        this.vendeur.length ||
        this.acheteur.length ||
        this.statut.length ||
        this.statut_client.length
      ) {
        return true;
      }
      return false;
    },

    totalPages() {
      if (this.TotalRowsScis) {
        return Math.ceil(this.TotalRowsScis / this.per_page);
      }
      return this.TotalRowsScis;
    },
    computedCheckedRowsOneClient() {
      let tableReglement = [];
      let tableReglementCheck = [];
      let filiale_list = this.getUserData.filiales.map(item => item.id);
      if (this.factureScis.length) {
        this.factureScis.forEach(element => {
          element.factures.forEach(facture => {
            if (facture.check == true) {
              tableReglementCheck.push(facture);
            }
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type != 'Facture proforma' &&
              (facture.etat == 'A payer' || facture.etat == 'Payé en partie') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id) &&
                    facture.client_id == tableReglement[0].client_id
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (
                    facture.client_id == tableReglement[0].client_id &&
                    facture.vendeur_id == tableReglement[0].vendeur_id
                  ) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        });
        return tableReglement.length == tableReglementCheck.length &&
          tableReglement.length > 0
          ? true
          : false;
      }
      return false;
    },
    computedCheckedRowsOneClientFacture() {
      let tableReglement = [];

      if (this.factureScis.length) {
        let filiale_list = this.getUserData.filiales.map(item => item.id);

        this.factureScis.forEach(element => {
          element.factures.forEach(facture => {
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type != 'Facture proforma' &&
              (facture.etat == 'A payer' || facture.etat == 'Payé en partie') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id) &&
                    facture.client_id == tableReglement[0].client_id
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (
                    facture.client_id == tableReglement[0].client_id &&
                    facture.vendeur_id == tableReglement[0].vendeur_id
                  ) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        });
      }
      return tableReglement;
    },
    computedSousObjets() {
      let sousObjets = this.sousObjetsMensuels.map(item => {
        return { value: item, text: item };
      });
      return sousObjets;
    },
    computedTotal() {
      let someHt = 0;
      let someTtc = 0;
      let someApaye = 0;

      for (let i = 0; i < this.computedCheckedRows.length; i++) {
        for (let j = 0; j < this.computedCheckedRows[i].factures.length; j++) {
          if (
            this.computedCheckedRows[i].factures[j].check == true &&
            this.computedCheckedRows[i].factures[j].avoir_id == null
          ) {
            someHt =
              someHt +
                Math.round(
                  parseFloat(
                    this.computedCheckedRows[i].factures[j].net_ht +
                      ' '.replace(' ', '').replace(',', '.')
                  ) * 100
                ) /
                  100 || 0;
            someTtc =
              someTtc +
                Math.round(
                  parseFloat(
                    this.computedCheckedRows[i].factures[j].ttc +
                      ' '.replace(' ', '').replace(',', '.')
                  ) * 100
                ) /
                  100 || 0;
            if (
              this.computedCheckedRows[i].factures[j].type == 'Facture' &&
              (this.computedCheckedRows[i].factures[j].etat == 'A payer' ||
                this.computedCheckedRows[i].factures[j].etat ==
                  'Payé en partie' ||
                this.computedCheckedRows[i].factures[j].etat == 'Payé')
            ) {
              someApaye =
                someApaye +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].factures[j].reste +
                        '' +
                        ' '.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return {
        net_ht: Math.round(someHt * 100) / 100,
        ttc: Math.round(someTtc * 100) / 100,
        someApaye: Math.round(someApaye * 100) / 100
      };
    },
    computedDataToSend() {
      let selected = [];
      this.factureScis.forEach(element => {
        element.factures.forEach(facture => {
          if (
            facture.check == true &&
            facture.etat != 'Créé' &&
            facture.type != 'Facture proforma'
          ) {
            selected.push(facture);
          }
        });
      });
      return selected;
    },
    computedCheckRowsExport() {
      let selected = [];
      this.factureScis.forEach(element => {
        element.factures.forEach(facture => {
          if (facture.check == true) {
            selected.push(facture.id);
          }
        });
      });
      return selected;
    },
    computedCheckedRowsAvoir() {
      let tableF = this.factureScis?.filter(f => {
        return f.factures.some(
          e =>
            e.check == true &&
            e.type == 'Facture' &&
            e.avoir_id == null &&
            e.modifiable == true
        );
      });
      return tableF;
    },
    computedVendeurOnline() {
      let vendeurNames = this.getDataSciVendeurOnLine.map(item => {
        return { value: item.id, text: item.name };
      });
      return vendeurNames;
    },
    computedAcheteurOnline() {
      let acheteurNames = this.getDataSciAcheteurOnLine.map(item => {
        return { value: item.id, text: item.name };
      });
      return acheteurNames;
    },
    computedListStatutClient() {
      return [
        { value: 'A payer', text: 'à payer' },
        { value: 'Payé', text: 'Payée' }
      ];
    },
    computedListStatut() {
      return [
        { value: 'A payer', text: 'Facture à payer', $isDisabled: true },
        { value: 'Payé', text: 'Facture payée', $isDisabled: true },
        { value: 'Proforma annulée', text: 'Proforma annulée' }
      ];
    },
    computedGetIndex() {
      let arrayOfIndex = [];
      for (let i = 0; i < this.computedCheckedRows.length; i++) {
        for (let j = 0; j < this.computedCheckedRows[i].factures.length; j++) {
          if (
            this.computedCheckedRows[i].factures[j].check == true &&
            this.computedCheckedRows[i].factures[j].avoir_id !== null
          ) {
            arrayOfIndex.push({
              indexOfChild: this.computedCheckedRows[i].factures[j].id,
              indexOfFather: this.computedCheckedRows[i].name
            });
          }
        }
      }
      return arrayOfIndex;
    },
    computedCheckedRowsMultiple() {
      let tableF = this.factureScis?.filter(f => {
        return f.factures.some(
          e =>
            e.check == true &&
            e.type == 'Facture proforma' &&
            e.modifiable == true
        );
      });
      return tableF;
    },
    computedCheckRowsToTransfertLibre() {
      let selected = [];
      this.factureScis.forEach(element => {
        element.factures.forEach(facture => {
          if (
            facture.check == true &&
            facture.avoir_id !== null &&
            facture.etat == 'Annulée' &&
            facture.modifiable == true
          ) {
            selected.push(facture.id);
          }
        });
      });
      return selected;
    },
    computedCheckChipFilter() {
      if (
        this.vendeur.length > 0 ||
        this.acheteur.length > 0 ||
        this.statut.length > 0 ||
        this.statut_client.length > 0
      ) {
        return true;
      }
      return false;
    },
    computedFilter() {
      return {
        search: this.searchValue,
        etat: this.statut,
        vendeur: this.vendeur,
        acheteur: this.acheteur,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        col: this.column,
        famille: this.support
      };
    },
    computedCheckedRows() {
      let tableF = this.factureScis?.filter(f => {
        return f.factures.some(e => e.check == true);
      });
      return tableF;
    },
    computedValidationListFilter() {
      return [
        { value: 'Créé', text: 'Créé' },
        { value: 'A payer', text: 'A payer ' },
        { value: 'Payé', text: 'Payé' },
        { value: 'Payé en partie', text: 'Payé en partie' },
        { value: 'Annulée', text: 'Annulée' }
      ];
    },
    computedFields() {
      let fields = [
        {
          value: 'check_all',
          text: '',
          show: true,
          sortable: false,
          width: '40px !important',
          class: 'sticky-header-1'
        },
        {
          value: 'data-table-expand',
          text: '',
          show: true,
          sortable: false,
          width: '40px !important',
          class: 'sticky-header-2'
        },
        {
          value: 'Vendeur',
          text: 'Societé',
          show: this.checkPermission('FSACS'),
          sortable: false,
          class: 'societe-th'
        },
        {
          value: 'Acheteur',
          text: 'Client',
          show: this.checkPermission('FSACC'),
          sortable: false
        },
        {
          value: 'sous_objet',
          text: 'Mois',
          show: this.checkPermission('FSACMF'),
          sortable: false
        },
        {
          value: 'num',
          text: 'N° Facture',
          show: this.checkPermission('FSACNF'),
          sortable: false
        },
        {
          value: 'numero_avoir',
          text: 'N° Facture Avoir',
          show: this.checkPermission('FSACNFA'),
          sortable: false
        },
        {
          value: 'date',
          text: 'Date',
          show: this.checkPermission('FSACD'),
          sortable: false
        },
        {
          value: 'total_net_ht',
          text: 'Montant HT',
          show: this.checkPermission('FSACMH'),
          sortable: false
        },
        {
          value: 'total_tva',
          text: 'TVA',
          show: this.checkPermission('FSACT'),
          sortable: false
        },
        ,
        {
          value: 'total_ttc',
          text: 'Montant TTC',
          show: this.checkPermission('FSTTC'),
          sortable: false
        },
        {
          value: 'reste',
          text: 'Reste à payer',
          show: this.checkPermission('FSACRAP'),
          sortable: false
        },
        {
          value: 'etat',
          text: 'Statut',
          show: this.checkPermission('FSACSFA'),
          sortable: false
        },

        {
          value: 'statut_client',
          text: 'Statut client',
          show: this.checkPermission('FSACSC'),
          sortable: false
        },
        {
          value: 'date_paiement',
          text: 'Date prévisionnelle du paiement',
          show: this.checkPermission('FSACSCDP'),
          sortable: false
        },
        {
          value: 'reglement',
          text: 'Reglement',
          show: this.checkPermission('FSACSCRG'),
          sortable: false
        },
        {
          value: 'historique',
          text: 'Historique',
          show: this.checkPermission('FSACHF'),
          sortable: false
        }
      ];
      return fields.filter(item => item.show == true);
    }
  },
  methods: {
    ...mapActions([
      'getSousObjets',
      'updateStatutFactureSci',
      'updateStatutClientFactureSci',
      'updateMontantHtFactureSci',
      'exportFactureLibre',
      'getdataSciVendeurOnLine',
      'getdataSciAcheteurOnLine',
      'getFacturesScis',
      'downloadZIP',
      'avoirGlobaleFactureSci',
      'fecthFactureNumbersInExcelFile',
      'createAvoirSci',
      'getProductsFraisFacture',
      'validateFactureSciTemplate',
      'getTemplateFactureSci'
    ]),
    showRowDetailsVueDefault(item) {
      item.showDetails = !item.showDetails;
      if (item.showDetails == true) {
        if (!this.expanded.includes(item)) {
          this.expanded.push(item);
        }
      } else {
        const index = this.expanded.findIndex(
          expandedItem => expandedItem === item
        );
        if (index !== -1) {
          this.expanded.splice(index, 1);
        }
      }
    },
    disabledStartDate(date) {
      if (this.date_fin) {
        return (
          this.date_fin && moment(date, 'YYYY-MM-DD') <= new Date(this.date_fin)
        );
      }
      return true;
    },
    disabledEndDate(date) {
      if (this.date_debut) {
        return (
          this.date_debut &&
          moment(date, 'YYYY-MM-DD').add(1, 'days') > new Date(this.date_debut)
        );
      }
      return true;
    },
    openPopover() {
      this.menu = true;
    },
    closePopover() {
      this.menu = false;
      this.vendeur = [];
      this.acheteur = [];
      this.statut = [];
      this.statut_client = [];

      //POPOVER FILTER
      this.isLocalStorageVendeur = [];
      this.isLocalStorageAcheteur = [];
      this.isLocalStorageStatut = [];
      this.isLocalStorageStatutClient = [];

      this.handleFilter(true);
    },
    validerPopover() {
      //
      //SET FILTER DROM POPOVER
      //
      this.vendeur = this.isLocalStorageVendeur;
      this.acheteur = this.isLocalStorageAcheteur;
      this.statut = this.isLocalStorageStatut;
      this.statut_client = this.isLocalStorageStatutClient;
      //
      //END SET FILTER DROM POPOVER
      //
      this.handleFilter(true);
      this.menu = false;
    },
    searchWithMultipleFacture(value) {
      if (this.nums != value) {
        this.nums = value;
        this.handleFilter();
      }
    },
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    handleResetDate(name) {
      this[name] = null;
      this.dataSciOnLine();
      this.handleFilter();
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.dataSciOnLine();
      this.handleFilter();
    },
    dataSciOnLine() {
      this.getdataSciVendeurOnLine({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
      this.getdataSciAcheteurOnLine({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
    },
    handleFilter(value) {
      this.checkAll = false;
      this.setLocalStorageFactureSci();
      this.$nextTick(async () => {
        this.getFacturesScis({
          search: this.searchValue,
          etat: this.statut,
          vendeur_id: this.vendeur,
          acheteur_id: this.acheteur,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          page: this.page,
          per_page: this.per_page,
          order: 'DESC',
          column: 'id',
          famille: 'sci',
          mois: this.filterYearMonth,
          statut_client: this.statut_client,
          nums: this.nums,
          hiddenContent: value
        });
      });
    },
    reloadData() {
      this.checkAll = false;
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    checkAllFunction() {
      this.factureScis.map(item => {
        item.check = this.checkAll;
        item.factures.map(facture => {
          facture.check = this.checkAll;
        });
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.factures.map(facture => {
        facture.check = item.check;
      });
    },
    setLocalStorageFactureSci() {
      localStorage.setItem(
        'facture-sci',
        JSON.stringify({
          search: this.searchValue,
          statut: this.statut,
          statut_client: this.statut_client,
          vendeur: this.vendeur,
          acheteur: this.acheteur,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          filterYearMonth: this.filterYearMonth
        })
      );
    }
  },
  components: {
    VisualisationFacture: () =>
      import('@/views/component/Facture/VisualisationFacture.vue'),
    IconFiltre: () => import('@/assets/img/IconFiltre.vue'),
    InputFileExcelNumDossier: () =>
      import('../ThProject/componentRapportTh/inputFileExcelNumDossier.vue'),
    TransfertFactureToLibre: () =>
      import('@/views/component/Facture/TransfertFactureToLibre.vue'),
    TemplateFacture: () =>
      import('@/views/component/Facture/TemplateFacture.vue'),
    EditableInput,
    search: () => import('@/views/component/SearchInputComponent.vue'),
    AddFactureSciMensuelle: () =>
      import('@/views/component/Facture/AddFactureSciMensuelle.vue'),
    sendMail: () => import('@/views/component/mail/sendMail.vue'),
    ExportDynamic,
    ValidateMultiFacture: () =>
      import('@/views/component/Facture/ValidateMultiFacture.vue'),
    reglement: () => import('@/views/component/reglement/reglement.vue'),
    Historique: () => import('@/views/component/Historique.vue'),
    GenerateAvoir: () => import('@/views/component/avoir/GenerateAvoir.vue'),
    AvoirFacture: () => import('@/views/component/Facture/AvoirFacture.vue'),
    PopoverAvoirFacture: () =>
      import('@/views/component/Facture/PopoverAvoirFacture.vue'),
    PopoverReglementFacture: () =>
      import('@/views/component/Facture/PopoverReglementFacture.vue')
  },
  async mounted() {
    this.getSousObjets({
      date_debut: this.date_debut,
      date_fin: this.date_fin,
      famille: 'sci'
    });
    if (localStorage.getItem('facture-sci')) {
      var localStor = JSON.parse(localStorage.getItem('facture-sci'));
      this.filterYearMonth = localStor.filterYearMonth
        ? localStor.filterYearMonth
        : null;
      this.searchValue = localStor.search ? localStor.search : null;
      this.statut = localStor.statut ? localStor.statut : [];
      this.isLocalStorageStatut = this.statut;
      this.statut_client = localStor.statut_client
        ? localStor.statut_client
        : [];
      this.isLocalStorageStatutClient = this.statut_client;
      this.acheteur = localStor.acheteur ? localStor.acheteur : [];
      this.isLocalStorageAcheteur = this.acheteur;
      this.vendeur = localStor.vendeur ? localStor.vendeur  : [];
      this.isLocalStorageVendeur = this.vendeur ;
      this.date_debut = localStor.date_debut ? localStor.date_debut  : null;
      this.date_fin = localStor.date_fin ? localStor.date_fin: null;
    } else {
      this.setLocalStorageFactureSci();
    }
    this.dataSciOnLine();
    this.handleFilter();
  },
  directives: {
    ClickOutside
  }
};
</script>
